import React,{useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePageMain from './Components/HomePageMain';
import MainShopPage from './Components/Pages/Shop/MainShopPage';
import ResponsiveDrawer from './Components/adminPanel.js/AdminPanelMain';
function App() {

  const [cartItems, setCartItems] = useState({});
  
  const addToCart = (product) => {
    // Create a unique key for the product based on category and name
    const productKey = product.category + product.name;
  
    setCartItems(prevItems => {
      const newCart = { ...prevItems };
      if (newCart[productKey]) {
        newCart[productKey].quantity += 1;
      } else {
        newCart[productKey] = { ...product, quantity: 1 };
      }
      return newCart;
    });
  };
  
  
  const removeFromCart = (productId) => {
    setCartItems(prevItems => {
      const newCart = { ...prevItems };
      delete newCart[productId];
      return newCart;
    });
  };
  
  const incrementQuantity = (productId) => {
    setCartItems(prevItems => {
      const newCart = { ...prevItems };
      if (newCart[productId]) {
        newCart[productId].quantity += 1;
      }
      return newCart;
    });
  };
  
  const decrementQuantity = (productId) => {
    setCartItems(prevItems => {
      const newCart = { ...prevItems };
      if (newCart[productId] && newCart[productId].quantity > 1) {
        newCart[productId].quantity -= 1;
      } else {
        delete newCart[productId];
      }
      return newCart;
    });
  };

  
  const cartCount = Object.values(cartItems).reduce((total, item) => total + item.quantity, 0);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePageMain cartCount={cartCount} cartItems={cartItems} removeFromCart={removeFromCart} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} addToCart={addToCart} />} />
        <Route path="/shop" element={<MainShopPage  cartCount={cartCount} cartItems={cartItems} removeFromCart={removeFromCart} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} addToCart={addToCart}/>} />
        <Route path='/admin' element={<ResponsiveDrawer/>} />
      </Routes>
    </Router>
  );
}

export default App;
 