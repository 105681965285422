import React from 'react';

const ServiceCard = ({ icon, title, description, marginTop }) => {
  return (
    <div className={`flex flex-col items-center text-center p-4 ${marginTop}`}>
      <div className="text-green-500 text-4xl mb-2">{icon}</div>
      <h3 className="text-md font-semibold text-gray-800 mb-1">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const ServicesBanner = () => {
  return (
    <div className="bg-white">
      <div className="flex justify-center items-center py-6 md:py-8 mt-10">
        <div className="flex justify-around items-center w-full max-w-6xl mx-4">
          <ServiceCard
            icon={<img src='/images/1.png' alt="Free Shipping" />}
            title="FREE SHIPPING"
            description="For all order over $99"
          />
          <ServiceCard
            icon={<img src='/images/2.png' alt="Delivery On Time" />}
            title="DELIVERY ON TIME"
            description="If goods have problems"
          />
          <ServiceCard
            icon={<img src='/images/3.png' alt="Secure Payment" />}
            title="SECURE PAYMENT"
            description="100% secure payment"
            marginTop="mt-2"
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesBanner;
