


import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { auth, db } from '../../../Firebase'; // make sure the path is correct
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
};

const LoginModal = ({ open, handleClose }) => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    const toggleSignUp = () => setIsSignUp(!isSignUp);

    const handleSignUp = async (event) => {
        event.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            if (username) {
                await setDoc(doc(db, "users", user.uid), {
                    username: username,
                    email:email,
                });
            }
            // Store user info in localStorage
            localStorage.setItem('user', JSON.stringify({ email: user.email, uid: user.uid }));
            console.log("Account created, username saved, and user logged in!");
            handleClose();
        } catch (error) {
            console.error("Error signing up:", error);
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            // Store user info in localStorage
            localStorage.setItem('user', JSON.stringify({ email: user.email, uid: user.uid }));
            console.log("Logged in!");
            handleClose();
        } catch (error) {
            console.error("Error logging in:", error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('user');  // Clear user from local storage
        auth.signOut();  // Firebase sign out
        console.log("Logged out!");
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ pt: 3 }}>
                    {isSignUp ? 'Sign Up' : 'Log In'}
                </Typography>
                <form noValidate autoComplete="off" onSubmit={isSignUp ? handleSignUp : handleLogin}>
                    {isSignUp && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            autoFocus
                        />
                    )}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="email"
                        autoFocus={!isSignUp}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {isSignUp ? 'Sign Up' : 'Log In'}
                    </Button>
                    {!isSignUp && (
                        <Typography variant="body2" color="textSecondary" align="center">
                            {'Not a member? '}
                            <Button color="primary" onClick={toggleSignUp}>Sign Up</Button>
                        </Typography>
                    )}
                    <Divider sx={{ my: 2 }} />
                    {!isSignUp && (
                        <Typography variant="body2" color="textSecondary" align="center">
                            <Button color="primary" onClick={handleLogout}>Log Out</Button>
                        </Typography>
                    )}
                    {isSignUp && (
                        <Typography variant="body2" color="textSecondary" align="center">
                            {'Already have an account? '}
                            <Button color="primary" onClick={toggleSignUp}>Log In</Button>
                        </Typography>
                    )}
                </form>
            </Box>
        </Modal>
    );
};

export default LoginModal;
