

import React from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';


const ProductModal = ({ isOpen, onClose, product, addToCart }) => {
  if (!isOpen) return null;

  const { image, category, name, price, description } = product; // Including category in destructuring

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative bg-white p-8 rounded-xl max-w-2xl w-full mx-4 shadow-2xl">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-400 hover:text-gray-500">
          <CloseIcon />
        </button>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <img src={image} alt={name} className="w-full h-auto object-contain" />
          <div className="flex flex-col">
            <span className="text-sm font-medium text-red uppercase">{category}</span>
            <h3 className="text-3xl font-bold mb-4">{name}</h3>
            <p className="text-gray-600 mb-4">{description}</p>
            <p className="text-2xl font-bold text-red mb-4">{price}</p>
          
            
            <button className="bg-red hover:bg-red text-white px-6 py-3 rounded-lg transition-colors mt-5" 
              onClick={() => {
                addToCart({ ...product });
                onClose(); // Close the modal after adding to cart
              }}
            >
              ADD TO CART
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ProductModal;

