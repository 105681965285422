import React, { useState,useEffect } from 'react';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ProductModal from '../../Model';
import IconButton from '@mui/material/IconButton';
import { db } from '../../../Firebase'; 
import { collection, getDocs } from 'firebase/firestore';


const ProductCard = ({ image, category, name, price, description,addToCart }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const product = { image, category, name, price, description}; // Add more product details as needed
    const handleAddToCartClick = () => {
      const productToAdd = { image, category, name, price, description };
      addToCart(productToAdd);
    };
    return (
        <>
      <div className="flex flex-col items-center text-center p-3 shadow-lg hover:shadow-lg transition-shadow duration-300 relative group w-[250px] mb-5 h-[400px]">
        <img
          src={image}
          alt={name}
          className="w-full h-[250px] object-cover mb-2 transition-transform duration-300 ease-in-out group-hover:scale-105"
        />
        <div className="text-xs uppercase text-gray-500 mb-1" style={{ color:"#eb3d3a", fontWeight: 'bolder' }}>{category}</div>
        <h5 className="text-sm font-semibold text-gray-100" style={{ color:"#adadad" }}>{name}</h5>
        <div className="text-md font-bold mb-10">{price}</div>
        <div className="flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute bottom-3 left-1/2 transform -translate-x-1/2">
        
        <IconButton
  aria-label="Add to bag"
  className="p-1 bg-red rounded-full"
  onClick={handleAddToCartClick}
  style={{ 
    color: 'white',
    backgroundColor:'red',
    padding: '8px', 
    borderRadius: '50%', 
    display: 'inline-flex', 
    justifyContent: 'center',
    alignItems: 'center'
  }}
>
  <ShoppingBagOutlinedIcon fontSize="small" />
</IconButton>

        <button aria-label="View details" onClick={() => setModalOpen(true)} className="p-1"><VisibilityOutlinedIcon fontSize="small" /></button>
        </div>
      </div>
       <ProductModal isOpen={modalOpen} onClose={() => setModalOpen(false)} product={product} addToCart={addToCart}/>
       </>
    );
  };



const CategorySection = ({ category, products,addToCart  }) => (
    <>
      <h3 className="text-xl font-bold mb-4 mt-8 text-red">{category}</h3>
      <div className="flex overflow-x-auto space-x-4 p-4">
  {products.map(product => (
    <div key={product.id} className="flex-none w-[250px]">
      <ProductCard {...product} addToCart={addToCart}/>
    </div>
  ))}
</div>
    </>
  );
  const FeaturedProducts = ({ products, filter ,addToCart}) => {
    let content;
  
    if (filter === 'All') {
      // Group products by category
      const categoryGroups = products.reduce((groups, product) => {
        groups[product.category] = [...(groups[product.category] || []), product];
        return groups;
      }, {});
  
      content = Object.entries(categoryGroups).map(([category, products]) => (
        <CategorySection key={category} category={category} products={products} addToCart={addToCart}/>
      ));
    } else {
      const filteredProducts = products.filter(product => product.category === filter);
      content = filteredProducts.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {filteredProducts.map(product => (
            <div key={product.id} className="px-2">
              <ProductCard {...product} />
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-center">There are no items in this category.</p>
      );
    }
  
    return (
      <div className="p-6">
        <div className="w-[80%] mx-auto">
          {content}
        </div>
      </div>
    );
  };
    

const ShopComponent = ({ addToCart }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState(['All']);
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const fetchedCategories = querySnapshot.docs.map(doc => doc.data().name);
        
        // Remove 'All' if it exists in the fetched data to avoid duplication
        const uniqueCategories = fetchedCategories.filter(category => category !== 'All');
        
        // Prepend 'All' to ensure it is always first
        setCategories(['All', ...uniqueCategories]);
      } catch (error) {
        console.error("Error fetching categories: ", error);
        // Handle the error appropriately, such as showing a user-friendly message
      }
    };
  
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const fetchedProducts = querySnapshot.docs.map(doc => ({
          id: doc.id, // Assuming you want to keep track of the document ID
          ...doc.data()
        }));
        setProductsData(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);
  return (
    <div className="bg-white pt-20">
        <div className="text-center mb-6">
        <h2 className="text-4xl font-bold inline-block relative">
          Featured Products
          <span className="block h-1 w-16 bg-red absolute left-1/2 -translate-x-1/2 mt-2"></span>
        </h2>
      </div>
       
      <div className="text-center">
      {categories.map(category => (
    <button
      key={category}
      onClick={() => setSelectedCategory(category)}
      className={`px-4 py-2 font-semibold ${selectedCategory === category ? 'border-b-2 border-red' : ''}`}
      style={{ color: selectedCategory === category ? "#eb3d3a" : "#adadad" }}
    >
      {category}
    </button>
  ))}
      </div>
      <FeaturedProducts products={productsData} filter={selectedCategory} addToCart={addToCart} />
    </div>
  );
};

export default ShopComponent;
