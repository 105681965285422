import React, { useState,useEffect } from 'react';

import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ProductModal from './Model';
import Slider from 'react-slick';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';
import { db } from '../Firebase'; 
import { collection, getDocs } from 'firebase/firestore';

 
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        right: '-4%', // Positioned on the right side
        transform: "translateY(-50%)",
        zIndex: 2,
        backgroundColor: "#eb3d3a", // Red background
        color: "white", // White icon color
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        left: '-5%', // Positioned on the left side
        transform: "translateY(-50%)",
        zIndex: 2,
        backgroundColor: "#eb3d3a", // Red background
        color: "white", // White icon color
      }}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
};



const ProductCard = ({ image, category, name, price, description,addToCart }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const product = { image, category, name, price, description}; // Add more product details as needed
    const handleAddToCartClick = () => {
      const productToAdd = { image, category, name, price, description };
      addToCart(productToAdd);
    };
    return (
        <>
      <div className="flex flex-col items-center text-center p-3 shadow-lg hover:shadow-lg transition-shadow duration-300 relative group w-[250px]  mb-5 h-[400px]">
        <img
          src={image}
          alt={name}
          className="w-full h-[250px] object-cover mb-2 transition-transform duration-300 ease-in-out group-hover:scale-105"
        />
        <div className="text-xs uppercase text-gray-500 mb-1" style={{ color:"#eb3d3a", fontWeight: 'bolder' }}>{category}</div>
        <h5 className="text-sm font-semibold text-gray-100" style={{ color:"#adadad" }}>{name}</h5>
        <div className="text-md font-bold mb-10">{price}</div>
        <div className="flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute bottom-3 left-1/2 transform -translate-x-1/2">
         
        <a href='https://shop.rosebudcamrose.com/menu/rosebud-cannabis/'>  <IconButton
  aria-label="Add to bag"
  className="p-1 bg-red rounded-full"
  style={{ 
    color: 'white',
    backgroundColor:'red',
    padding: '8px', 
    borderRadius: '50%', 
    display: 'inline-flex', 
    justifyContent: 'center',
    alignItems: 'center'
  }}
>
  <ShoppingBagOutlinedIcon fontSize="small" />
</IconButton></a>

      <a href='https://shop.rosebudcamrose.com/menu/rosebud-cannabis/'><button aria-label="View details" className="p-1"><VisibilityOutlinedIcon fontSize="small" /></button></a>
          
          
        </div>
      </div>
       <ProductModal isOpen={modalOpen} onClose={() => setModalOpen(false)} product={product} addToCart={addToCart}/>
       </>
    );
  };



const FeaturedProducts = ({ products, filter,addToCart }) => {
  const filteredProducts = products.filter(product => filter === 'All' || product.category === filter);

  // Slider settings
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="p-6">
        <div className="w-[80%] sm:w-[80%] md:w-[60%]  mx-auto">
        {filteredProducts.length > 0 ? (
          <Slider {...settings}>
            {filteredProducts.map(product => (
              <div key={product.id} className="px-2">
                <ProductCard {...product} addToCart={addToCart} />
              </div>
            ))}
          </Slider>
        ) : (
          <p className="text-gray-500 text-center">There are no items in this category.</p>
        )}
      </div>
    </div>
  );
};

const ShopComponent = ({addToCart}) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState(['All']);
  const [productsData, setProductsData] = useState([]);
  console.log(typeof addToCart); 
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const fetchedCategories = querySnapshot.docs.map(doc => doc.data().name);
        
        // Remove 'All' if it exists in the fetched data to avoid duplication
        const uniqueCategories = fetchedCategories.filter(category => category !== 'All');
        
        // Prepend 'All' to ensure it is always first
        setCategories(['All', ...uniqueCategories]);
      } catch (error) {
        console.error("Error fetching categories: ", error);
        // Handle the error appropriately, such as showing a user-friendly message
      }
    };
  
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const fetchedProducts = querySnapshot.docs.map(doc => ({
          id: doc.id, // Assuming you want to keep track of the document ID
          ...doc.data()
        }));
        setProductsData(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);
  
  
  return (
    <div className="bg-white mt-20">
        <div className="text-center mb-6">
        <h2 className="text-4xl font-bold inline-block relative">
          Featured Products
          <span className="block h-1 w-16 bg-red absolute left-1/2 -translate-x-1/2 mt-2"></span>
        </h2>
      </div>
       
      <div className="text-center">
      {categories.map(category => (
    <button
      key={category}
      onClick={() => setSelectedCategory(category)}
      className={`px-4 py-2 font-semibold ${selectedCategory === category ? 'border-b-2 border-red' : ''}`}
      style={{ color: selectedCategory === category ? "#eb3d3a" : "#adadad" }}
    >
      {category}
    </button>
  ))}
      </div>
      <FeaturedProducts products={productsData} filter={selectedCategory} addToCart={addToCart}/>
    </div>
  );
};

export default ShopComponent;
