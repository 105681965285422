
import React, { useState, useEffect } from 'react';
import { db } from '../../Firebase'; // Make sure this path is correct
import { collectionGroup, getDocs, doc, getDoc,onSnapshot  } from 'firebase/firestore';
import { Card, CardContent, Typography, List, ListItem, Divider, Box, Grid } from '@mui/material';

function AllOrdersList() {
  const [allOrders, setAllOrders] = useState([]);

 useEffect(() => {
  const ordersRef = collectionGroup(db, 'orders');

  const unsubscribe = onSnapshot(ordersRef, (querySnapshot) => {
    const ordersPromises = querySnapshot.docs.map(async (orderDoc) => {
      const orderData = orderDoc.data();
      let userData = {};

      if (orderData.userId) {
        const userRef = doc(db, 'users', orderData.userId);
        try {
          const userSnap = await getDoc(userRef);
          userData = userSnap.data() || {};
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }

      return {
        ...orderData, // Spread all orderData including timestamp
        userData, // Spread all userData
        date: orderData.timestamp ? orderData.timestamp.toDate().toLocaleDateString() : 'Unknown date',
        clientName: userData.username || 'Unknown user',
        clientEmail: userData.email || 'No email',
      };
    });

    Promise.all(ordersPromises).then(ordersWithUser => {
      // Sort the orders by timestamp in descending order before setting the state
      const sortedOrders = ordersWithUser.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
      setAllOrders(sortedOrders);
    })
    .catch(error => console.error('Error processing orders:', error));
  });

  return () => unsubscribe();
}, []);

  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h4" gutterBottom>All Orders</Typography>
      <Divider sx={{ my: 2 }} />
      {allOrders.map((order, index) => (
        <Card key={index} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h6" color="text.primary">
              Date: {order.date}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Client: {order.clientName} - {order.clientEmail}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              {order.items && Object.entries(order.items).map(([productName, productDetails], idx) => (
                <Grid item xs={12} md={6} key={idx}>
                  <Typography variant="subtitle1" style={{color:'blue'}}>{productDetails.name}</Typography>
                  <Typography variant="body2" style={{color:'red'}}>Category: {productDetails.category}</Typography>
                  <Typography variant="body2">Description: {productDetails.description}</Typography>
                  <Typography variant="body2">Quantity: {productDetails.quantity}</Typography>
                  <Typography variant="body2" style={{color:'green'}}>Price: {productDetails.price}</Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}

export default AllOrdersList;
