


import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider,
  Typography, TextField, Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import AddCategory from './HandleCategories';
import AddProduct from './AddProducts';
import ManageProducts from './ManageProducts';
import AllOrdersList from './OrderList';
import { auth2 } from '../../AdminFirebase';  // Adjust the path as necessary
import { signInWithEmailAndPassword } from "firebase/auth";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// Placeholder components for content
const Inbox = () => <Typography variant="h4"><AddCategory/></Typography>;
const Starred = () => <Typography variant="h4"><AddProduct/></Typography>;
const SendEmail = () => <Typography variant="h4"><ManageProducts/></Typography>;
const Drafts = () => <Typography variant="h4"><AllOrdersList/></Typography>;

// Component map
const components = {
  Categories: Inbox,
  Add_Products: Starred,
  Manage_Products: SendEmail,
  Orders: Drafts
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function ResponsiveDrawer() {
  const [open, setOpen] = React.useState(false);
  const [authenticated, setAuthenticated] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [selectedComponent, setSelectedComponent] = React.useState('Categories');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (componentName) => {
    setSelectedComponent(componentName);
    handleDrawerClose();
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth2, email, password);
      setAuthenticated(true);
      localStorage.setItem('userLoggedIn', 'true'); // Save authentication state
    } catch (error) {
      alert(`Login Failed: ${error.message}`);
    }
  };

  const handleSignOut = () => {
    setAuthenticated(false);
    localStorage.removeItem('userLoggedIn'); // Remove authentication state
  };

  React.useEffect(() => {
    const userLoggedIn = localStorage.getItem('userLoggedIn');
    if (userLoggedIn === 'true') {
      setAuthenticated(true);
    }
  }, []);

  if (!authenticated) {
    return (
      <div className="flex items-center justify-center h-screen">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSignIn}>
          <div>
            <Typography variant="h5" component="h3">
              Admin Sign In
            </Typography>
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              fullWidth
              type="password"
              margin="normal"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit" color="primary" variant="contained">
              Sign In
            </Button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Store Admin Panel
          </Typography>
          <div style={{ flexGrow: 1 }}></div> 
          <IconButton color="inherit" onClick={handleSignOut}>
            <ExitToAppIcon />
          </IconButton>

        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        sx={{ width: 300, flexShrink: 0, '& .MuiDrawer-paper': { width: 300 } }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {Object.keys(components).map((text, index) => (
            <ListItem button key={text} onClick={() => handleListItemClick(text)}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main style={{ padding: "1rem" }}>
        {React.createElement(components[selectedComponent])}
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
