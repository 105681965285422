import React from 'react';

const PaymentFooter = () => {
  return (
    
    <footer className="bg-purple text-white">
    <div className="container mx-auto py-4 px-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <span>Copyright © 2024 Rosebud - All Rights Reserved.</span>
      
        <img src="/images/payment.png" alt="Payment Methods" />
    </div>
</footer>

  );
};

export default PaymentFooter;
