

import React, { useEffect, useState } from 'react';
import { db } from '../../Firebase'; // Ensure correct path
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { Card, CardContent, CardMedia, Typography, IconButton, Grid, Box, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ManageProducts = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [search, setSearch] = useState("");
    const [editProductId, setEditProductId] = useState(null);
    const [formData, setFormData] = useState({ name: '', price: '', description: '' });

    useEffect(() => {
        const fetchProducts = async () => {
            const querySnapshot = await getDocs(collection(db, "products"));
            const productsArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setProducts(productsArray);
            setFilteredProducts(productsArray);
        };
        fetchProducts();
    }, []);

    const handleDelete = async (id) => {
        await deleteDoc(doc(db, "products", id));
        const updatedProducts = products.filter(product => product.id !== id);
        setProducts(updatedProducts);
        setFilteredProducts(updatedProducts);
    };

    const startEdit = (product) => {
        setEditProductId(product.id);
        setFormData({ name: product.name, price: product.price, description: product.description });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const saveEdit = async () => {
        await updateDoc(doc(db, "products", editProductId), formData);
        const updatedProducts = products.map(product => 
            product.id === editProductId ? { ...product, ...formData } : product
        );
        setProducts(updatedProducts);
        setFilteredProducts(updatedProducts);
        setEditProductId(null);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        const lowerCaseValue = e.target.value.toLowerCase();
        const filtered = products.filter(product => product.name.toLowerCase().includes(lowerCaseValue));
        setFilteredProducts(filtered);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <TextField
                fullWidth
                label="Search Products"
                variant="outlined"
                value={search}
                onChange={handleSearchChange}
                margin="normal"
            />
            <Grid container spacing={2}>
                {filteredProducts.map((product) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={product.id}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={product.image || "/static/images/cards/placeholder.png"}
                                alt={product.name}
                            />
                            {editProductId === product.id ? (
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleEditChange}
                                        size="small"
                                    />
                                    <TextField
                                        fullWidth
                                        label="Price"
                                        name="price"
                                        value={formData.price}
                                        onChange={handleEditChange}
                                        size="small"
                                    />
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleEditChange}
                                        multiline
                                        rows={2}
                                        size="small"
                                    />
                                    <Button onClick={saveEdit} color="primary">Save</Button>
                                </CardContent>
                            ) : (
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">{product.name}</Typography>
                                    <Typography>{product.description}</Typography>
                                    <Typography variant="body2" color="text.secondary">Price: {product.price}</Typography>
                                </CardContent>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton aria-label="edit" onClick={() => startEdit(product)} style={{color:'blue'}}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => handleDelete(product.id)} style={{color:'red'}}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ManageProducts;
