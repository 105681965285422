import React, { useState,useEffect } from 'react';
import { db, storage } from '../../Firebase'; // Make sure this path is correct
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { InputLabel, Select, MenuItem } from '@mui/material';

const AddProduct = () => {
    const [productData, setProductData] = useState({
        category: '',
        name: '',
        price: '',
        description: ''
    });
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Fetch categories from Firestore
        const fetchCategories = async () => {
            const querySnapshot = await getDocs(collection(db, "categories"));
            setCategories(querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));
        };

        fetchCategories();
    }, []); 

   

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Automatically add "US$" to price if not present
        if (name === 'price' && !value.startsWith('US$')) {
            setProductData(prevData => ({
                ...prevData,
                [name]: `US$${value}`
            }));
        } else {
            setProductData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleAddProduct = async () => {
        if (!file) {
            alert('Please select an image to upload.');
            return;
        }

        // Upload image to Firebase Storage
        const storageRef = ref(storage, `products/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Handle progress
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading file: ", error);
                alert('Error uploading file: ' + error.message);
            },
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    // Save product data along with image URL to Firestore
                    const product = { ...productData, image: downloadURL };
                    try {
                        const docRef = await addDoc(collection(db, "products"), product);
                        alert("Product added successfully!");
                        setProductData({
                            category: '',
                            name: '',
                            price: '',
                            description: ''
                        });
                        setFile(null);
                        setUploadProgress(0);
                    } catch (e) {
                        console.error("Error adding document: ", e);
                        alert('Error adding product: ' + e.message);
                    }
                });
            }
        );
    };

    return (
        <Box
            sx={{
                p: 4,
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 3,
                borderRadius: 2,
                bgcolor: 'background.paper',
            }}
        >
            <h2>Add New Product</h2>
          <InputLabel id="category-label" fullWidth>Category</InputLabel>
                <Select
                fullWidth
                    labelId="category-label"
                    id="category"
                    value={productData.category}
                    label="Category"
                    name="category"
                    onChange={handleChange}
                >
                    {categories.map((category) => (
                        <MenuItem key={category.id} value={category.name}>
                            {category.name}
                        </MenuItem>
                    ))}
                </Select>
            <TextField
                fullWidth
                label="Name"
                variant="outlined"
                name="name"
                value={productData.name}
                onChange={handleChange}
                margin="normal"
            />
            <TextField
                fullWidth
                label="Price"
                variant="outlined"
                name="price"
                value={productData.price}
                onChange={handleChange}
                margin="normal"
            />
            <TextField
                fullWidth
                label="Description"
                variant="outlined"
                name="description"
                value={productData.description}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
            />
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" sx={{ mt: 2 }}>
                    Upload Image
                </Button>
            </label>
            {file && <span>{file.name}</span>}
            {uploadProgress > 0 && <LinearProgress variant="determinate" value={uploadProgress} />}
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddProduct}
                sx={{ mt: 2 }}
            >
                Add Product
            </Button>
        </Box>
    );
};

export default AddProduct;
