import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';

const Footer = () => (
    <>
     <footer className="bg-gray-100 hidden md:flex">
    <div className="container mx-auto py-8 px-4 grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
      <div className="mb-6 md:mb-0">
        <img src="/images/LogoNew.png" alt="Rosebud" className="mb-4 mx-auto md:mx-0" style={{ height: '64px', width: 'auto' }} />
        <p className='text-gray-light'>Address: 3912 44 Ave, Camrose, AB T4V 3H2, Canada</p>
        <p className='text-gray-light'>Phone: +1 780-679-5645</p>
        <p className='text-gray-light'>Email: contact@rosebud cannabis.com</p>
        <div className="flex justify-center md:justify-start mt-4">
          <FacebookIcon className="text-gray-600 mx-1" />
          <TwitterIcon className="text-gray-600 mx-1" />
          <LinkedInIcon className="text-gray-600 mx-1" />
          <PinterestIcon className="text-gray-600 mx-1" />
        </div>
      </div>
      <div className="mb-6 md:mb-0 py-5">
        <h5 className="font-bold mb-2 py-5">INFORMATION</h5>
        <ul className="list-none">
          <li className='text-gray-light'>About us</li>
          <li className='pt-2 text-gray-light'>Check out</li>
          <li className='pt-2 text-gray-light'>Contact</li>
          <li className='pt-2 text-gray-light'>Service</li>
        </ul>
      </div>
      <div className="mb-6 md:mb-0 py-5">
        <h5 className="font-bold mb-2 py-5">MY ACCOUNT</h5>
        <ul className="list-none">
          <li className='text-gray-light'>My account</li>
          <li className='pt-2 text-gray-light'>Contact</li>
          <li className='pt-2 text-gray-light'>Shopping Cart</li>
          <li className='pt-2 text-gray-light'>Tracking Order</li>
        </ul>
      </div>
      <div className='mb-6 md:mb-0 py-10'>
        <h5 className="font-bold mb-2">QUICK SHOP</h5>
        <ul className="list-none py-5">
          <li className='text-gray-light'>About us</li>
          <li className='pt-2 text-gray-light'>Check out</li>
          <li className='pt-2 text-gray-light'>Contact</li>
          <li className='pt-2 text-gray-light'>Service</li>
        </ul>
      </div>
    </div>
  </footer>

  <footer className="bg-gray-100 md:hidden">
    <div className="container mx-auto py-8 px-2">
      {/* Logo and contact info */}
      <div className="flex flex-col items-center md:flex-row lgu:flex-row md:justify-between md:items-center mb-6 md:mb-0">
        <img src="/images/LogoNew.png" alt="Rosebud" className="mb-4" style={{ height: '64px', width: 'auto' }} />
        <div className="text-center">
          <p className='text-gray-light'>Address: 3912 44 Ave, Camrose, AB T4V 3H2, Canada</p>
          <p className='text-gray-light'>Phone: +1 780-679-5645 </p>
          <p className='text-gray-light'>Email: contact@rosebudcannabis.com</p>
        </div>
        <div className="flex justify-center md:justify-end mt-4 md:mt-0">
          <FacebookIcon className="text-gray-600 mx-1" />
          <TwitterIcon className="text-gray-600 mx-1" />
          <LinkedInIcon className="text-gray-600 mx-1" />
          <PinterestIcon className="text-gray-600 mx-1" />
        </div>
      </div>
      {/* Additional Info */}
      <div className="grid grid-cols-3 md:flex md:justify-between md:items-center text-center md:text-left">
        <div className="mb-6 md:mb-0">
          <h5 className="font-bold mb-2">INFORMATION</h5>
          <ul className="list-none">
            <li className='text-gray-light'>About us</li>
            <li className='text-gray-light'>Check out</li>
            <li className='text-gray-light'>Contact</li>
            <li className='text-gray-light'>Service</li>
          </ul>
        </div>
        <div className="mb-6 md:mb-0">
          <h5 className="font-bold mb-2">MY ACCOUNT</h5>
          <ul className="list-none">
            <li className='text-gray-light'>My account</li>
            <li className='text-gray-light'>Contact</li>
            <li className='text-gray-light'>Shopping Cart</li>
            <li className='text-gray-light'>Tracking Order</li>
          </ul>
        </div>
        <div className="mb-6 md:mb-0">
          <h5 className="font-bold mb-2">QUICK SHOP</h5>
          <ul className="list-none">
            <li className='text-gray-light'>About us</li>
            <li className='text-gray-light'>Check out</li>
            <li className='text-gray-light'>Contact</li>
            <li className='text-gray-light'>Service</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>

 

    </>
 
);

export default Footer;





