
// Navbar.js
import React, { useState,useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoginModal from './Pages/Shop/LoginModel'; // make sure to import LoginModal from the correct path
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { NavLink } from 'react-router-dom';
import { doc, setDoc, serverTimestamp, collection } from "firebase/firestore"; // Firestore methods
import { db } from '../Firebase'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function OrderPlacedModal({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"Order Placed"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your order has been placed successfully.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
}

function Navbar({ cartCount,cartItems,removeFromCart ,incrementQuantity ,decrementQuantity}) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [orderPlaced, setOrderPlaced] = useState(false);

    useEffect(() => {
      const user = localStorage.getItem('user');
      if (user) {
          setIsLoggedIn(true);
          setUserDetails(JSON.parse(user));
      } else {
          setIsLoggedIn(false);
          setUserDetails(null);
      }
  }, [modalOpen]);
  



const handleCheckout = async () => {
  if (!isLoggedIn) {
    setModalOpen(true);
  } else {
    try {
      const cartData = {
        userId: userDetails.uid,
        items: cartItems,
        timestamp: serverTimestamp(),
        date: new Date().toLocaleDateString()
      };
      // Generate a new document reference for an order
      const orderRef = doc(collection(db, "users", userDetails.uid, "orders"));
      await setDoc(orderRef, cartData);
      console.log("Cart data saved successfully!");
      setOrderPlaced(true);
    } catch (error) {
      console.error("Error saving cart data:", error);
    }
  }
};


    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleModalToggle = () => {
        setModalOpen(!modalOpen);
    };

    const toggleCartDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setCartDrawerOpen(open);
    };

    const handleLogout = () => {
      localStorage.removeItem('user');  // Clear user from local storage
      setIsLoggedIn(false);
      console.log("Logged out!");
  };

    const renderCartItem = (item, productId) => (
      <div key={productId} className="flex items-center justify-between px-4 py-2 border-b">
        <img src={item.image} alt={item.name} className="h-16 w-16 object-cover mr-4" />
        <div className="flex-1">
          <h5 className="font-bold">{item.name}</h5>
          <p className="text-sm text-gray-600">{item.price}</p>
        </div>
        <div className="flex items-center">
        <OutlinedInput
      id="outlined-adornment-quantity"
      type="text"
      value={item.quantity}
      onChange={() => {}}
      startAdornment={
        <InputAdornment position="start">
          <IconButton
            onClick={() => decrementQuantity(productId)}
            aria-label="delete item"
            edge="start"
            style={{color:'red'}}
          >
            <DeleteIcon />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
         
          <IconButton
            onClick={() => incrementQuantity(productId)}
            aria-label="add item"
            edge="end"
          >
            <AddIcon />
          </IconButton>
        </InputAdornment>
      }
      labelWidth={0}
      style={{ width: '130px', margin: '0 10px' }}
    />
    </div>
       
      </div>
    );
    
    
  


    const calculateSubtotal = () => {
      return Object.values(cartItems).reduce((total, item) => {
          return total + (parseFloat(item.price.slice(3)) * item.quantity);
      }, 0);
  };
  
  const calculateTax = () => {
    const subtotal = calculateSubtotal();
    return subtotal * 0.005; // 0.5% tax
};


const calculateTotal = () => {
  const subtotal = calculateSubtotal();
  const tax = subtotal * 0.005; // Assuming a 0.5% tax rate
  return subtotal + tax;
};


    const drawer = (
        <div className="flex flex-col w-72 h-full bg-white">
            <div className="flex justify-between items-center p-4">
                <div className="flex justify-center flex-grow py-2">
                    <img src='/images/LogoNew.png' className="h-24" alt="logo" />
                </div>
                <IconButton
                    onClick={handleDrawerToggle}
                    sx={{ position: 'absolute', right: '0', top: '0' }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <List component="nav" className="flex-grow">
            {['Home', 'Shop', 'Blog', 'About' ].map((text) => (
        <ListItem button key={text} className="border-b border-gray-200">
            <ListItemText primary={<NavLink to={text === 'Shop' ? 'https://shop.rosebudcamrose.com/menu/rosebud-cannabis/' : `/`} onClick={() => setDrawerOpen(false)} className={({ isActive }) => "pl-4 font-bold" + (isActive ? ' text-red-500' : '')}>
                {text}
            </NavLink>} />
        </ListItem>
    ))}
            </List>
        </div>
    );

    return (
        <>
            <nav className="bg-black bg-opacity-70 text-white absolute w-full z-10 h-[70px]">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center sm:hidden">
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon style={{ fontSize: '35px' }} />
                            </IconButton>
                            <Drawer
                                anchor="left"
                                open={drawerOpen}
                                onClose={handleDrawerToggle}
                            >
                              {drawer}
                            </Drawer>
                        </div>
                        <div className="hidden sm:flex-shrink-0 sm:flex sm:items-center">
                        <img src='/images/LogoNew.png' className="h-16" alt="logo" />
                    </div>
                        <div className="hidden sm:flex sm:space-x-4">
                        {['Home', 'Shop', 'Blog', 'About' ].map((text) => (
        <ListItem button key={text} className="border-b border-gray-200">
            <ListItemText primary={<NavLink to={text === 'Shop' ? 'https://shop.rosebudcamrose.com/menu/rosebud-cannabis/' : `/`} onClick={() => setDrawerOpen(false)} className={({ isActive }) => "pl-4 font-bold" + (isActive ? ' text-red-500' : '')}>
                {text}
            </NavLink>} />
        </ListItem>
    ))}
                        </div>
                        <div className="flex items-center space-x-4">
                             
                           <a href='https://shop.rosebudcamrose.com/menu/rosebud-cannabis/'>
                           <IconButton color="inherit" aria-label="cart">
      <ShoppingCartIcon />
      
    </IconButton></a> 
                        </div>
                    </div>
                </div>
                <Drawer
    anchor="right"
    open={cartDrawerOpen}
    onClose={toggleCartDrawer(false)}
    PaperProps={{
      style: { width: '22rem' } // You can set the width as per your design requirement
    }}
     
   
>
    <div className="flex flex-col w-full h-full">
        <h2 className="p-4 font-bold text-xl">Your Cart</h2>
        <IconButton
            onClick={toggleCartDrawer(false)}
            sx={{ position: 'absolute', right: '0', top: '0' }}
        >
            <CloseIcon />
        </IconButton>
        <div className="flex flex-col justify-between h-full">
            <div className="overflow-y-auto">
            {Object.entries(cartItems).length === 0 ? (
  <div className="p-4 text-center">Your cart is empty.</div>
) : (
  Object.entries(cartItems).map(([productKey, item]) => renderCartItem(item, productKey))
)}

            </div>
            <div className="bg-white p-4 sticky bottom-0">
    <div className="text-lg font-bold mb-2">Cart Totals</div>
    <div className="flex justify-between mb-1">
        <div>Subtotal</div>
        <div>${calculateSubtotal().toFixed(2)}</div>
    </div>
    <div className="flex justify-between mb-1">
        <div>Total tax</div>
        <div>${calculateTax().toFixed(2)}</div>
    </div>
    <div className="flex justify-between mb-4 font-bold">
        <div>Total</div>
        <div>${calculateTotal().toFixed(2)}</div>
    </div>
    <button className="w-full bg-red text-white py-2 rounded-md text-lg font-bold focus:outline-none hover:bg-red-600 transition-colors" onClick={handleCheckout}>
        Checkout
    </button>
</div>
        </div>
    </div>
</Drawer>

            </nav>
            <LoginModal open={modalOpen} handleClose={handleModalToggle} />
            <OrderPlacedModal
      open={orderPlaced}
      onClose={() => {
        setOrderPlaced(false); 
        setTimeout(() => {
          window.location.reload();
        }, 2000);  // 3000 milliseconds = 3 seconds
      }}
    />
        </>
    );
}

export default Navbar;


