

import React from 'react';

const Feature = ({ icon, title}) => (
  <div className="flex flex-col items-center text-center p-4">
    <img src={icon} alt={title} className="h-8 w-8 mb-2" />
    <h5 className="text-lg font-bold mb-1 text-white">{title}</h5>
  
  </div>
);

const WhyChooseUs = () => (
    <>
    


    <section 
    className="relative bg-cover bg-center mb-10 flex justify-center items-center text-white mt-10" 
    style={{ backgroundImage: 'url("/images/bg.png")' }}
  >
    {/* Apply a minimum height for smaller screens and a fixed height for larger screens */}
    <div className="w-[60%] margin-auto  p-4 text-center sm:min-h-[60vh] py-[10%]">
      <h2 className="text-3xl font-bold mb-8">Why choose us</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1">
        <Feature 
          icon="/images/one.png" 
          title="Eat Healthier" 
          description="Modi tempora incidunt ut labore dolore magnam aliquam" 
        />
        <Feature 
          icon="/images/two.png" 
          title="We Have Brands" 
          description="Modi tempora incidunt ut labore dolore magnam aliquam" 
        />
        <Feature 
          icon="/images/three.png" 
          title="Fresh And Clean" 
          description="Modi tempora incidunt ut labore dolore magnam aliquam" 
        />
        <Feature 
          icon="/images/four.png" 
          title="Modern Process" 
          description="Modi tempora incidunt ut labore dolore magnam aliquam" 
        />
      </div>
    </div>
  </section>
    
    


    </>

);

export default WhyChooseUs;

