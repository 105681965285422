// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBFZMkazzyLIZ54HjLGZkr68dFa6Yj8f70",
  authDomain: "claytonstore-2f0f3.firebaseapp.com",
  projectId: "claytonstore-2f0f3",
  storageBucket: "claytonstore-2f0f3.appspot.com",
  messagingSenderId: "407298821765",
  appId: "1:407298821765:web:86534372cc49bc0effe3ec",
  measurementId: "G-7G8GN36K0H"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);