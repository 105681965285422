// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig2 = {
    apiKey: "AIzaSyD78Wc4GEjp_q45AHmIVzTEKe8Uco3OPEg",
  authDomain: "clyatonstoreadmin.firebaseapp.com",
  projectId: "clyatonstoreadmin",
  storageBucket: "clyatonstoreadmin.appspot.com",
  messagingSenderId: "862346819241",
  appId: "1:862346819241:web:b6faa987db464a2d2d5bb8",
  measurementId: "G-F818WXHVXC"
  };

// Initialize Firebase
const app2 = initializeApp(firebaseConfig2, "AdminApp");

// Initialize Firestore

export const auth2 = getAuth(app2);
