
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

// Import your other components
import Navbar from './Navbar';
import Slider from './Slider';
import WelcomeBanner from './WelcomSection';
import ServicesBanner from './ServicesBanner';
import ShopComponent from './ShopProduct';
import WhyChooseUs from './WHyChooseUs';
import Footer from './Footer';
import PaymentFooter from './PaymentFooter';

const HomePageMain = ({ cartCount, cartItems, removeFromCart, incrementQuantity, decrementQuantity, addToCart }) => {
  const [ageGateVisible, setAgeGateVisible] = useState(false);
  const [dob, setDob] = useState({ day: '', month: '', year: '' });
  const [isUnderage, setIsUnderage] = useState(false);

  useEffect(() => {
    const ageVerified2 = localStorage.getItem('ageVerified2');
    if (!ageVerified2) {
      setAgeGateVisible(true);
    }
  }, []);

  const handleAgeCheck = () => {
    const birthDate = new Date(dob.year, dob.month - 1, dob.day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear(); // Change const to let here
    const m = today.getMonth() - birthDate.getMonth();
  
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    if (age >= 18) {
      sessionStorage.setItem('ageVerified2', 'true'); // Set a session flag
      localStorage.setItem('ageVerified2', 'true');
      setAgeGateVisible(false);
    } else {
      setIsUnderage(true);
    }
  };
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    let validatedValue = value;
  
    if (name === 'month') {
      validatedValue = value > 12 ? '12' : value < 1 ? '1' : value;
    } else if (name === 'day') {
      validatedValue = value > 31 ? '31' : value < 1 ? '1' : value;
    } else if (name === 'year') {
      const currentYear = new Date().getFullYear();
      validatedValue = value > currentYear ? String(currentYear) : value;
    }
  
    setDob({ ...dob, [name]: validatedValue });
  };
  

  const handleTabClose = () => {
    localStorage.removeItem('ageVerified2');
  };

  useEffect(() => {
    // Attach the event listener for beforeunload
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <>
     <Dialog open={ageGateVisible} onClose={() => {}} maxWidth="sm" fullWidth>
        <DialogTitle className="text-center">
          {/* Logo placeholder */}
          <div className="flex justify-center items-center mb-4">
            {/* Add your logo here */}
            <img src="/images/LogoNew.png" alt="Your Logo" className="h-[150px] w-auto mb-5" />
          </div>
          Enter Date of Birth For Access
        </DialogTitle>
        <DialogContent>
          
          {isUnderage && (
            <Alert severity="error" className="mb-4">
              You must be 18 or older to access this site.
            </Alert>
          )}
          <div className="flex space-x-4 justify-center">
            <TextField
              margin="dense"
              name="day"
              label="Day"
              type="number"
              onChange={handleChange}
              value={dob.day}
              variant="outlined"
              className="w-1/4"
            />
            <TextField
              margin="dense"
              name="month"
              label="Month"
              type="number"
              onChange={handleChange}
              value={dob.month}
              variant="outlined"
              className="w-1/4"
            />
            <TextField
              margin="dense"
              name="year"
              label="Year"
              type="number"
              onChange={handleChange}
              value={dob.year}
              variant="outlined"
              className="w-1/4"
            />
          </div>
        </DialogContent>
        <DialogActions className="justify-center pb-6">
          <Button onClick={handleAgeCheck} color="primary" variant="contained" size="large" style={{backgroundColor:'red'}}>
            Enter
          </Button>
        </DialogActions>
      </Dialog>

      <Navbar cartCount={cartCount} cartItems={cartItems} removeFromCart={removeFromCart} incrementQuantity={incrementQuantity} decrementQuantity={decrementQuantity} />
      <Slider />
      <WelcomeBanner />
      <ServicesBanner />
      <ShopComponent addToCart={addToCart} />
      <WhyChooseUs />
      <Footer />
      <PaymentFooter />
    </>
  );
};

export default HomePageMain;
