
import React, { useState, useEffect } from 'react';
import { db } from '../../Firebase';  // Adjust the import path based on your project structure
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const AddCategory = () => {
    const [categoryName, setCategoryName] = useState('');
    const [categories, setCategories] = useState([]);

    // Fetch categories when component mounts
    useEffect(() => {
        const fetchCategories = async () => {
            const querySnapshot = await getDocs(collection(db, "categories"));
            setCategories(querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));
        };

        fetchCategories();
    }, [categories]);

    const handleAddCategory = async () => {
        if (categoryName.trim() !== "") {
            try {
                const docRef = await addDoc(collection(db, "categories"), {
                    name: categoryName.trim()
                });
                setCategories([...categories, { id: docRef.id, name: categoryName.trim() }]);
                setCategoryName(''); // Reset the input field after successful insertion
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    };

    const handleDeleteCategory = async (id) => {
        try {
            await deleteDoc(doc(db, "categories", id));
            setCategories(categories.filter(category => category.id !== id));
        } catch (e) {
            console.error("Error deleting document: ", e);
        }
    };

    return (
        <Box
            sx={{
                p: 2,
                maxWidth: 500,
                my: 2,
                boxShadow: 3,
                borderRadius: 1,
            }}
        >
            <h2>Add New Category</h2>
            <TextField
                fullWidth
                label="Category Name"
                variant="outlined"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddCategory}
                sx={{ mt: 2, mb: 2 }}
            >
                Add Category
            </Button>
            <h1>Previous Categories</h1>
            {categories.map(category => (
                <Box key={category.id} display="flex" alignItems="center" mt={1}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        disabled
                        value={category.name}
                    />
                    <IconButton
                        color="secondary"
                        onClick={() => handleDeleteCategory(category.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
        </Box>
    );
};

export default AddCategory;
