

import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const WelcomeBanner = () => {
  return (
    <Container className="my-4 mt-10">
      <Box className="flex flex-col md:flex-row justify-between items-center bg-white p-5">
        <Box className="mb-4 md:mb-0 md:flex-1"><img src='/images/Slogan.avif' alt="Slogan"/></Box>
        <Box className="md:flex-1 text-left">
        <Typography
  variant="h3"
  component="h1"
  className="text-gray-800 text-left sm:text-left sm:whitespace-normal text-xl sm:text-xl"
  style={{ fontWeight: 500 }}
>
  Welcome To Rosebud
</Typography>



          <Typography variant="body1" className="mt-2 text-gray-light">
            Welcome to Rosebud Cannabis, your uniquely discreet and locally rooted
            adult-use cannabis retailer in Camrose, Alberta, providing an enjoyable and
            informative experience with our generational ties and extensive knowledge
            of legal Canadian cannabis.
          </Typography>
          <Box className="flex flex-col items-start mt-4">
            <img src="/images/signature.png" alt="Signature" className="mb-2" />
            <Typography variant="subtitle1" className=" text-gray-800" style={{ fontWeight: 600 }}>
              Clayton Skinner
            </Typography>
            <Typography variant="subtitle2" style={{ color:"#eb3d3a", fontWeight: 'bolder' }}>
              LEADER
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default WelcomeBanner;


