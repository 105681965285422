
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
// You can add more images to this array if you like
const images = ["/images/StorePic1.jpg", "/images/StorePic2.jpg"];

function CarouselSlider() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true); // Begin the transition
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsTransitioning(false); // End the transition
      }, 500); // This timeout duration should match the CSS transition duration
    }
  };

  const handlePrev = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        setIsTransitioning(false);
      }, 500);
    }
  };

  return (
    <div className="relative h-[800px]">
      {images.map((src, index) => (
        <div
          key={src}
          className={`absolute inset-0 transition-opacity duration-500 ${
            activeIndex === index ? "opacity-100" : "opacity-0"
          }`}
          style={{
            filter: activeIndex === index && !isTransitioning ? "none" : "blur(2px)"
          }}
        >
          <img src={src} alt={`Image ${index + 1}`} className="w-full h-full object-cover" />
        </div>
      ))}

    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col items-center" >
        <img src="/images/LogoNew.png" alt="Your Logo" className="h-[150px] w-auto mb-5" />
        
       
        </div>
        
      </div>

      <div className="absolute inset-0 flex justify-between items-center">
        <IconButton
          onClick={handlePrev}
          style={{
            position: "absolute",
            top: "50%",
            left: 20,
            transform: "translateY(-50%)",
            zIndex: 2,
            backgroundColor: "#eb3d3a",
          }}
        >
          <ArrowBackIosIcon style={{ color: "white" }} />
        </IconButton>
        <IconButton
          onClick={handleNext}
          style={{
            position: "absolute",
            top: "50%",
            right: 20,
            transform: "translateY(-50%)",
            zIndex: 2,
            backgroundColor: "#eb3d3a",
          }}
        >
          <ArrowForwardIosIcon style={{ color: "white" }} />
        </IconButton>
      </div>
      <a href="https://shop.rosebudcamrose.com/menu/rosebud-cannabis/">
        <Button variant="contained" color="primary" style={{backgroundColor:"#eb3d3a",borderRadius: '20px',width: '150px', cursor: 'pointer'}} className="absolute top-[500px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Shop Now
        </Button>
        </a>
    </div>
  );
}

export default CarouselSlider;
